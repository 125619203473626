exports.components = {
  "component---src-components-catch-catch-jsx": () => import("./../../../src/components/Catch/catch.jsx" /* webpackChunkName: "component---src-components-catch-catch-jsx" */),
  "component---src-components-product-product-jsx": () => import("./../../../src/components/Product/product.jsx" /* webpackChunkName: "component---src-components-product-product-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/About.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-catches-jsx": () => import("./../../../src/pages/Catches.jsx" /* webpackChunkName: "component---src-pages-catches-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/Contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/Privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-products-contentful-product-type-index-jsx": () => import("./../../../src/pages/Products/{contentfulProduct.type}/index.jsx" /* webpackChunkName: "component---src-pages-products-contentful-product-type-index-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/Products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-suppliers-jsx": () => import("./../../../src/pages/Suppliers.jsx" /* webpackChunkName: "component---src-pages-suppliers-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/Terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

